/**
 * Process Instance Form.
 * @module utilities/processinstanceform
 *
 */
import { ActivityModel, AttributeModel, ProcessInstanceModel, ProcessModel } from '../models';
import { Button, Col, Icon, Row, Spinner, StepperContainer, StepperContent, StepperNav } from 'design-react-kit';
import { InlineWidgets, WidgetsButtons } from './widgetshelper';
import React, { createRef, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { CMDBuildForm } from './formhelper';
import { ProcessActionButtons } from './actionbuttons';
import { ProcessStateStepper } from '../utilities/processstatestepper';
import { SaveRequestModal } from './saverequestmodal';
import SideMenu from './sidenavbar';
import { executeTriggersBeforeAction } from './formtriggers';
import { useProcessModel } from '../modelsuses';

const messages = defineMessages({
  go_back: {
    id: 'go_back',
    defaultMessage: 'Indietro',
  },
  save_request: {
    id: 'save_request',
    defaultMessage: 'Salva richiesta',
  },
  advance: {
    id: 'advance',
    defaultMessage: 'Avanti',
  },
  infoPrivacy: {
    id: 'infoPrivacy',
    defaultMessage: 'Informativa privacy',
  },
});

const ProcessInstanceForm = ({ processName, instanceId, activityId, resetAction, config }: { processName: string; instanceId: number; activityId: string; resetAction: Function; config: object }) => {
  let documentBody = createRef();
  const intl = useIntl();
  const { getProcess, getProcessAttributes, getProcessInstance, getProcessActivity, advanceProcessInstance, saveProcessInstance } = useProcessModel();

  const [process, setProcess] = useState<ProcessModel | null>();
  const [processInstance, setProcessInstance] = useState<ProcessInstanceModel | null>();
  const [processActivity, setProcessActivity] = useState<ActivityModel | null>();
  const [activityAttributes, setActivityAttributes] = useState<AttributeModel[] | object[] | null>();
  const [fieldsets, setFieldsets] = useState([]);
  const [processAttributes, setProcessAttributes] = useState<AttributeModel[] | object[] | null>();
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, toggleModal] = useState(false);

  useEffect(() => {
    // load process info
    getProcess(processName).then((response) => {
      setProcess(response);
    });

    // load attributes
    getProcessAttributes(processName).then((response) => {
      setProcessAttributes(response);
    });

    let activityRequestParams: [string | number] = [processName];
    if (instanceId && activityId) {
      // load process instance
      getProcessInstance(processName, instanceId).then((data) => {
        setProcessInstance(data);
      });
      // add instance id and activity id in activity request parameters
      activityRequestParams.push(instanceId, activityId);
    } else {
      // set empty process instance
      setProcessInstance({
        _type: processName,
      });
    }
    // load activity
    getProcessActivity.apply(this, activityRequestParams).then((activity) => {
      setProcessActivity(activity);
    });
  }, []);

  useEffect(() => {
    if (processActivity) {
      const attributes: AttributeModel[] | undefined = processActivity.attributes?.map((attr) => {
        return {
          ...attr.detail,
          index: attr.index,
          writable: attr.writable,
          mandatory: attr.mandatory,
          action: attr.action,
        } as AttributeModel;
      });
      setActivityAttributes(attributes);
    }
  }, [processActivity]);

  useEffect(() => {
    if (process) {
      setFieldsets(process.attributeGroups);
    }
  }, [process]);

  const [formTriggerExecuted, setFormTriggerExecuted] = useState(false);
  useEffect(() => {
    if (process && processInstance && !formTriggerExecuted) {
      setFormTriggerExecuted(true);
      const isNewInstance = !processInstance._id;
      let formTriggers = [];
      if (isNewInstance) {
        formTriggers = process.formTriggers?.filter((ft: object) => ft.beforeInsert && ft.active);
      }
      // execute form triggers
      if (formTriggers.length) {
        executeTriggersBeforeAction(formTriggers, processInstance, setProcessInstance);
      }
    }
  }, [process, processInstance]);

  const advanceHandler = (updatedInstance) => {
    setIsSaving(true);
    advanceProcessInstance(processName, updatedInstance._id, processActivity?._id, updatedInstance).then((response) => {
      if (response?.success) {
        const processInstanceData = response.data;
        setActivityAttributes([]);
        setProcessInstance({});
        const current_activity = processInstanceData?._tasklist ? processInstanceData?._tasklist[0] : { _id: '' };
        getProcessActivity(processInstanceData?._type, processInstanceData?._id, current_activity._id).then((activity) => {
          setProcessActivity(activity);
          setIsSaving(false);
        });
        setProcessInstance(processInstanceData);
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        setIsLoading(false);
      }
    });
  };

  const saveHandler = (updatedInstance) => {
    setIsSaving(true);
    saveProcessInstance(processName, updatedInstance._id, processActivity?._id, updatedInstance).then((response) => {
      if (response?.success) {
        toggleModal(!isOpen);
        setIsSaving(false);
      }
    });
  };

  const backHandler = (updatedInstance) => {
    resetAction();
  };

  const onAdvanceClick = () => {
    setIsSaving(true);
    advanceProcessInstance(processName, processInstance._id, processActivity?._id, processInstance).then((response) => {
      if (response?.success) {
        const processInstanceData = response.data;
        setActivityAttributes([]);
        setProcessInstance({});
        if (processInstanceData?._tasklist.length === 0) {
          return window.location.reload();
        }
        const current_activity = processInstanceData?._tasklist ? processInstanceData?._tasklist[0] : { _id: '' };
        getProcessActivity(processInstanceData?._type, processInstanceData?._id, current_activity._id).then((activity) => {
          setProcessActivity(activity);
          setIsSaving(false);
        });
        setProcessInstance(processInstanceData);
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        setIsLoading(false);
      } else {
        return window.location.reload();
      }
    });
  };

  const onSaveClick = () => {
    setIsSaving(true);
    saveProcessInstance(processName, processInstance._id, processActivity?._id, processInstance).then((response) => {
      if (response?.success) {
        // TODO: ....
        setIsSaving(false);
      }
    });
  };

  const [currentStep, setCurrentStep] = useState(0);

  const handleNextClick = () => {
    setIsLoading(true);
    setCurrentStep(currentStep + 1);
    onAdvanceClick();
  };

  return (
    <>
      <StepperContainer>
        {/* PASSARE SOLO GLI ATTRIBUTI E IL CONTESTO */}
        <ProcessStateStepper attributes={processAttributes} context={processInstance} />
        <Row className="border-top row-column-border row-column-menu-left">
          <Col tag="aside" lg={4}>
            <SideMenu data={documentBody} groups={fieldsets} />
          </Col>
          <section id="main-content-section" className="col-lg-8 it-page-sections-container">
            {/* SEZIONI */}
            <StepperContent className="h-auto border-0 bg-white d-block">
              <div className="it-page-sections-container" ref={documentBody}>
                <CMDBuildForm groups={fieldsets} attributes={activityAttributes || []} layout={processActivity?.formStructure?.form || {}} widgets={processActivity?.widgets || []} context={processInstance || {}} updateContext={setProcessInstance} activity={processActivity || ({} as ActivityModel)} model={process} />
              </div>
              <InlineWidgets widgets={processActivity?.widgets || []} target={processInstance} />
              <WidgetsButtons widgtes={processActivity?.widgets || []} context={processInstance || {}} />
            </StepperContent>
            {processActivity?.writable ? (
              <StepperNav>
                <ProcessActionButtons
                  attributes={activityAttributes}
                  processInstance={processInstance}
                  processInstanceUpdater={setProcessInstance}
                  isLoading={isLoading || isSaving}
                  saveAction={saveHandler}
                  advanceAction={advanceHandler}
                  backAction={backHandler}
                />
              </StepperNav>
            ) : null}
          </section>
        </Row>
      </StepperContainer>
      <SaveRequestModal
        isOpen={isOpen}
        toggleModal={toggleModal}
      />
    </>
  );
};

export default ProcessInstanceForm;
