/**
 * ReservationDetails view component.
 * @module components/View/ReservationDetails
 */

import PropTypes from 'prop-types';
import {
  Card,
  StepperContainer,
  StepperContent,
  CardBody,
  Input,
} from 'design-react-kit';
import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { useSelector } from 'react-redux';

const messages = defineMessages({
  requester: {
    id: 'requester',
    defaultMessage: 'Richiedente',
  },
  requesterDescription: {
    id: 'requesterDescription',
    defaultMessage: 'Informazioni su di te',
  },
  details: {
    id: 'details',
    defaultMessage: 'Dettagli',
  },
  detailsDescription: {
    id: 'detailsDescription',
    defaultMessage: 'Aggiungi ulteriori dettagli',
  },
  firstName: {
    id: 'firstName',
    defaultMessage: 'Nome',
  },
  firstNameDescription: {
    id: 'firstNameDescription',
    defaultMessage: 'Inserisci il tuo nome',
  },
  lastName: {
    id: 'lastName',
    defaultMessage: 'Cognome',
  },
  lastNameDescription: {
    id: 'lastNameDescription',
    defaultMessage: 'Inserisci il tuo cognome',
  },
  email: {
    id: 'email',
    defaultMessage: 'Email',
  },
  emailDescription: {
    id: 'emailDescription',
    defaultMessage: 'Inserisci la tua email',
  },
  phone: {
    id: 'phone',
    defaultMessage: 'phone',
  },
  phoneDescription: {
    id: 'phoneDescription',
    defaultMessage: 'Inserisci il tuo telefono',
  },
  showEverything: {
    id: 'showEverything',
    defaultMessage: 'Mostra tutto',
  },
  contacts: {
    id: 'contacts',
    defaultMessage: 'Contatti',
  },
  fiscalNumber: {
    id: 'fiscalNumber',
    defaultMessage: 'Codice fiscale',
  },
});

/**
 * ReservationDetails view component class.
 * @function ReservationDetails
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const ReservationDetails = ({ currentState, setRequesterData }) => {
  const intl = useIntl();
  const userSession = useSelector((state) => state.portalUser);

  const handleRequesterDataChange = (event) => {
    const { name, value } = event.target;
    setRequesterData((prevData) => {
      const updatedField = Object.keys(messages).find(
        (key) => intl.formatMessage(messages[key]) === name,
      );
      if (updatedField) {
        return {
          ...prevData,
          [intl.formatMessage(messages[updatedField])]: value,
        };
      }
      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (userSession.isAuthenticated) {
      setRequesterData({
        [intl.formatMessage(messages.firstName)]: userSession?.user?.first_name,
        [intl.formatMessage(messages.lastName)]: userSession?.user?.last_name,
        [intl.formatMessage(messages.fiscalNumber)]:
          userSession?.user?.fiscal_number,
      });
    }
  }, [userSession.isAuthenticated]);

  return currentState === 3 ? (
    <>
      <RichTextSection
        tag_id={intl.formatMessage(messages.requester)}
        field="text"
        title={intl.formatMessage(messages.requester)}
        show_title={false}
      >
        <StepperContainer>
          <StepperContent
            className="steppers-content border-0 h-100"
            aria-live="polite"
          >
            <div className="col-10 h-100">
              <section className="it-page-section h-100">
                <h2 className="mt-4">
                  {userSession.isAuthenticated === false
                    ? intl.formatMessage(messages.requester)
                    : userSession?.user?.first_name +
                      ' ' +
                      userSession?.user?.last_name}
                </h2>
                <Card className="card p-big my-4 h-100">
                  <CardBody className="mt-4">
                    {userSession.isAuthenticated === true ? (
                      <Input
                        type="text"
                        label={intl.formatMessage(messages.fiscalNumber)}
                        value={userSession?.user?.fiscal_number}
                      />
                    ) : (
                      <>
                        <Input
                          type="text"
                          name={intl.formatMessage(messages.firstName)}
                          label={intl.formatMessage(messages.firstName)}
                          infoText={intl.formatMessage(
                            messages.firstNameDescription,
                          )}
                          onChange={handleRequesterDataChange}
                        />
                        <Input
                          type="text"
                          name={intl.formatMessage(messages.lastName)}
                          label={intl.formatMessage(messages.lastName)}
                          infoText={intl.formatMessage(
                            messages.lastNameDescription,
                          )}
                          onChange={handleRequesterDataChange}
                        />
                      </>
                    )}
                    <Input
                      type="email"
                      name={intl.formatMessage(messages.email)}
                      label={intl.formatMessage(messages.email)}
                      infoText={intl.formatMessage(messages.emailDescription)}
                      onChange={handleRequesterDataChange}
                    />
                    <Input
                      type="text"
                      name={intl.formatMessage(messages.phone)}
                      label={intl.formatMessage(messages.phone)}
                      infoText={intl.formatMessage(messages.phoneDescription)}
                      onChange={handleRequesterDataChange}
                    />
                  </CardBody>
                </Card>
              </section>
            </div>
          </StepperContent>
        </StepperContainer>
      </RichTextSection>
    </>
  ) : (
    ''
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ReservationDetails.propTypes = {
  content: PropTypes.shape({
    relatedItems: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default ReservationDetails;
