/**
 * Reservation view component.
 * @module components/View/Reservation
 */

import {
  PageHeader,
  RelatedItems,
  useSideMenu,
  ContentTypeViewSections,
  SkipToMainContent,
  RichTextSection,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { createRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Container,
  Accordion,
  AccordionHeader,
  Progress,
  AccordionBody,
} from 'design-react-kit';

import ReservationStepper from './ReservationStepper';
import ReservationTime from './ReservationTime';
import ReservationDetails from './ReservationDetails';
import ReservationRequester from './ReservationRequester';
import ReservationSummary from './ReservationSummary';
import ReservationConfirmation from './ReservationConfirmation';
import ReservationStepperNav from './ReservationStepperNav';
import cx from 'classnames';

export const ReservationSectionsOrder = [
  { /* TIME */ component: ReservationTime },
  { /* DETAILS */ component: ReservationDetails },
  { /* REQUESTER */ component: ReservationRequester },
  { /* SUMMARY */ component: ReservationSummary },
  { /* CONFIRMATION */ component: ReservationConfirmation },
  { /* STEPPER NAV */ component: ReservationStepperNav },
];

const messages = defineMessages({
  avaiableAppointments: {
    id: 'avaiableAppointments',
    defaultMessage: 'Appuntamenti disponibili',
  },
  office: {
    id: 'office',
    defaultMessage: 'Ufficio',
  },
  officeDescription: {
    id: 'officeDescription',
    defaultMessage: "Scegli l'ufficio a cui vuoi richiedere l'appuntamento",
  },
  officeType: {
    id: 'officeType',
    defaultMessage: 'Tipologia di ufficio',
  },
  go_back: {
    id: 'go_back',
    defaultMessage: 'Indietro',
  },
  save_request: {
    id: 'save_request',
    defaultMessage: 'Salva richiesta',
  },
  advance: {
    id: 'advance',
    defaultMessage: 'Avanti',
  },
  infoPrivacy: {
    id: 'infoPrivacy',
    defaultMessage: 'Informativa privacy',
  },
  january: {
    id: 'january',
    defaultMessage: 'Gennaio',
  },
  february: {
    id: 'february',
    defaultMessage: 'Febbraio',
  },
  march: {
    id: 'march',
    defaultMessage: 'Marzo',
  },
  april: {
    id: 'april',
    defaultMessage: 'Aprile',
  },
  may: {
    id: 'may',
    defaultMessage: 'Maggio',
  },
  june: {
    id: 'june',
    defaultMessage: 'Giugno',
  },
  july: {
    id: 'july',
    defaultMessage: 'Luglio',
  },
  august: {
    id: 'august',
    defaultMessage: 'Agosto',
  },
  september: {
    id: 'september',
    defaultMessage: 'Settembre',
  },
  october: {
    id: 'october',
    defaultMessage: 'Ottobre',
  },
  november: {
    id: 'november',
    defaultMessage: 'Novembre',
  },
  december: {
    id: 'december',
    defaultMessage: 'Dicembre',
  },
  selectMonth: {
    id: 'selectMonth',
    defaultMessage: 'Seleziona un mese',
  },
  noDaysAvail: {
    id: 'noDaysAvail',
    defaultMessage: 'Nessun appuntamento disponibile per questo mese.',
  },
  index: {
    id: 'index',
    defaultMessage: 'Indice della pagina',
  },
});

/**
 * Reservation view component class.
 * @function Reservation
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const Reservation = (props) => {
  const intl = useIntl();
  let documentBody = createRef();
  const content = useSelector((state) => state.content.data);
  const { sideMenuElements, SideMenu } = useSideMenu(content, documentBody);
  // const [sideMenuElements, setSideMenuElements] = useState([]);

  const [readingtime, setReadingtime] = useState(0);
  const [currentState, setCurrentState] = useState(1);
  const [selectedAppointment, setSelectedAppointment] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [moreDetails, setMoreDetails] = useState('');
  const [requesterData, setRequesterData] = useState('');
  const [bookings, setBookings] = useState('');
  const [currentBooking, setCurrentBooking] = useState('');
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [createResponse, setCreateResponse] = useState();
  const [progressValue, setProgressValue] = useState(0);
  const [activeSection, setActiveSection] = useState(null);
  const [messageError, setMessageError] = useState(false);

  const months = [
    { value: '01', label: intl.formatMessage(messages.january) },
    { value: '02', label: intl.formatMessage(messages.february) },
    { value: '03', label: intl.formatMessage(messages.march) },
    { value: '04', label: intl.formatMessage(messages.april) },
    { value: '05', label: intl.formatMessage(messages.may) },
    { value: '06', label: intl.formatMessage(messages.june) },
    { value: '07', label: intl.formatMessage(messages.july) },
    { value: '08', label: intl.formatMessage(messages.august) },
    { value: '09', label: intl.formatMessage(messages.september) },
    { value: '10', label: intl.formatMessage(messages.october) },
    { value: '11', label: intl.formatMessage(messages.november) },
    { value: '12', label: intl.formatMessage(messages.december) },
  ];

  const formatAppointmentDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('it-IT', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  if (!currentState) {
    setCurrentState(1);
  }

  const [isNavOpen, setIsNavOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const scrollHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const scrollPercentage = (scrollTop / scrollHeight) * 100;
      setProgressValue(scrollPercentage);
    };

    document.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    sideMenuElements &&
      sideMenuElements.children &&
      Array.from(sideMenuElements.children).map((section) => {
        setActiveSection(section.id);
      });
  }, [activeSection]);

  return (
    <>
      <Container className="container px-4 my-4 newsitem-view">
        <SkipToMainContent />
        <PageHeader
          content={content}
          readingtime={readingtime}
          showreadingtime={true}
          showdates={true}
          showtassonomiaargomenti={true}
        />
        <ReservationStepper
          content={content}
          currentState={currentState}
          setCurrentState={setCurrentState}
          selectedAppointment={selectedAppointment}
          selectedType={selectedType}
          requesterData={requesterData}
          bookings={bookings}
          setBookings={setBookings}
          currentBooking={currentBooking}
          setCurrentBooking={setCurrentBooking}
        />

        {/* HEADER IMAGE */}
        <div className="row row-column-border border-light row-column-menu-left">
          <aside className="col-lg-4">
            <div className="sticky-wrapper navbar-wrapper page-side-menu">
              <nav className="navbar it-navscroll-wrapper navbar-expand-lg">
                <div className="menu-wrapper">
                  <div className="link-list-wrapper menu-link-list">
                    <div className="accordion-wrapper">
                      <Accordion>
                        <AccordionHeader
                          active={isNavOpen}
                          onToggle={() => {
                            setIsNavOpen(!isNavOpen);
                          }}
                          aria-controls="side-menu-body"
                        >
                          <h3>{intl.formatMessage(messages.index)}</h3>
                        </AccordionHeader>
                        <div className="mb-3">
                          <Progress value={progressValue} role="progressbar" />
                        </div>
                        <AccordionBody
                          active={isNavOpen}
                          id="side-menu-body"
                          role="region"
                        >
                          <ul className="link-list" data-element="page-index">
                            {sideMenuElements &&
                              sideMenuElements.children &&
                              Array.from(sideMenuElements.children).map(
                                (item) => {
                                  if (item.nodeName === 'SECTION') {
                                    return (
                                      <li className="nav-item" key={item.id}>
                                        <a
                                          className={cx('nav-link active', {
                                            active: item.id === activeSection,
                                          })}
                                          href={`#${item.id}`}
                                          onClick={() => {
                                            document
                                              .getElementById(item?.id)
                                              ?.scrollIntoView?.({
                                                behavior: 'smooth',
                                                block: 'start',
                                              });
                                          }}
                                          id={`item-${item.id}`}
                                        >
                                          <span>{item.id}</span>
                                        </a>
                                      </li>
                                    );
                                  }
                                },
                              )}
                          </ul>
                        </AccordionBody>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </aside>
          <section
            className="col-lg-8 it-page-sections-container border-light"
            id="main-content-section"
            ref={documentBody}
          >
            {/* SEZIONI */}
            <ContentTypeViewSections
              content={content}
              defaultSections={ReservationSectionsOrder.map(
                (section, index) => ({
                  ...section,
                  props: {
                    currentState,
                    setCurrentState,
                    selectedAppointment,
                    setSelectedAppointment,
                    selectedType,
                    setSelectedType,
                    moreDetails,
                    setMoreDetails,
                    requesterData,
                    setRequesterData,
                    bookings,
                    setBookings,
                    currentBooking,
                    setCurrentBooking,
                    filteredAppointments,
                    setFilteredAppointments,
                    months,
                    selectedMonth,
                    setSelectedMonth,
                    formatAppointmentDate,
                    createResponse,
                    setCreateResponse,
                    messageError,
                    setMessageError,
                  },
                }),
              )}
            />
          </section>
        </div>
      </Container>
      <RelatedItems list={content?.relatedItems} />
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Reservation.propTypes = {
  content: PropTypes.shape({
    relatedItems: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default Reservation;
