/**
 * Process Instance Form.
 * @module utilities/actionbuttons
 *
 */

import { AttributeModel, ProcessInstanceModel } from '../models';
import { Button, Icon } from 'design-react-kit';
import { defineMessages, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';

import cx from 'classnames';
import { evaluateEcqlFilter } from './fieldshelper';
import { getLookupValuesProxy } from '../proxies/lookup';

const messages = defineMessages({
  save: {
    id: 'save',
    defaultMessage: 'Salva',
  },
  back: {
    id: 'back',
    defaultMessage: 'Indietro',
  },
  advance: {
    id: 'advance',
    defaultMessage: 'Avanti',
  },
});

export const ProcessActionButtons = ({ attributes, processInstance, processInstanceUpdater, isLoading, saveAction, advanceAction, backAction }: { attributes: AttributeModel[] | object[] | null | undefined; processInstance: ProcessInstanceModel | null | undefined; processInstanceUpdater: Function; isLoading: boolean; saveAction: Function; advanceAction: Function; backAction: Function }) => {
  const intl = useIntl();
  const [actionField, setActionField] = useState('');
  const [actions, setActions] = useState([]);

  useEffect(() => {
    const attribute: AttributeModel = attributes?.find((attr: AttributeModel) => attr.action) as AttributeModel;
    if (attribute) {
      setActionField(attribute.name);
      getLookupValuesProxy(attribute.lookupType, {
        filter: {
          ecql: evaluateEcqlFilter(attribute.ecqlFilter, processInstance),
        },
      }).then(({ data, meta }) => {
        let has_back = false;
        const actionsList = data?.map((lv) => {
          const isBack = lv.code.toLocaleLowerCase().indexOf('back') !== -1;
          has_back = isBack ? true : has_back;
          return {
            value: lv._id,
            label: lv._description_translation,
            code: lv.code,
            isBackAction: isBack,
            isSaveAction: false,
          };
        });

        const savePosition = has_back ? 1 : 0;
        actionsList.splice(savePosition, 0, {
          value: null,
          label: intl.formatMessage(messages.save),
          code: 'Save',
          isBackAction: false,
          isSaveAction: true,
        });

        if (!has_back) {
          actionsList.splice(0, 0, {
            value: null,
            label: intl.formatMessage(messages.back),
            code: 'Back',
            isBackAction: true,
            isSaveAction: false,
          });
        }

        setActions(actionsList);
      });
    }
  }, [attributes]);

  const onButtonClick = (action: { value: number | null; isBackAction: boolean; isSaveAction: boolean }) => {
    if (action.value) {
      const newInstance = {
        ...processInstance,
        [actionField]: action.value,
      };

      advanceAction(newInstance);
    } else if (action.isSaveAction) {
      saveAction(processInstance);
    } else if (action.isBackAction) {
      backAction(processInstance);
    }
  };

  return (
    <>
      {actionField ? (
        <>
          {actions?.map((action) => (
            <Button
              className={cx('', {
                'steppers-btn-prev': action.isBackAction,
                'steppers-btn-save': action.isSaveAction,
                'steppers-btn-confirm': !(action.isBackAction || action.isSaveAction),
              })}
              color="primary"
              outline={action.isBackAction || action.isSaveAction}
              size="sm"
              disabled={isLoading}
              onClick={() => {
                onButtonClick(action);
              }}
            >
              <Icon icon="it-chevron-left" />
              {action.label}
            </Button>
          ))}
        </>
      ) : (
        <>
          <Button
            className="steppers-btn-prev"
            color="primary"
            outline
            size="sm"
            disabled={isLoading}
            onClick={() => {
              backAction(processInstance);
            }}
          >
            <Icon icon="it-chevron-left" />
            {intl.formatMessage(messages.back)}
          </Button>
          <Button
            className="steppers-btn-save"
            color="primary"
            size="sm"
            disabled={isLoading}
            outline
            onClick={() => {
              saveAction(processInstance);
            }}
          >
            {intl.formatMessage(messages.save)}
          </Button>
          <Button
            className="steppers-btn-confirm"
            color="primary"
            size="sm"
            disabled={isLoading}
            onClick={() => {
              advanceAction(processInstance);
            }}
          >
            {intl.formatMessage(messages.advance)}
          </Button>
        </>
      )}
    </>
  );
};
