/**
 * PNHighlightEvents view component.
 * @module components/theme/View/PNHighlightEvents
 */
import { defineMessages, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { ContentImage } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { flattenToAppURL } from '@plone/volto/helpers';
import { viewDate } from 'design-comuni-plone-theme/helpers';
import { Row } from 'design-react-kit';

const messages = defineMessages({
  readMore: {
    id: 'readMore',
    defaultMessage: 'Leggi di più',
  },
  today: {
    id: 'today',
    defaultMessage: 'Oggi',
  },
  next7: {
    id: 'next7',
    defaultMessage: 'Prossimi 7 giorni',
  },
  next30: {
    id: 'next30',
    defaultMessage: 'Prossimi 30 giorni',
  },
  highlightEvents: {
    id: 'highlightEvents',
    defaultMessage: 'Eventi in evidenza',
  },
});
/**
 * PNHighlightEvents view component class.
 * @function PNHighlightEvents
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const PNHighlightEvents = ({
  content,
  dateFilter,
  forChildrenFilter,
  onlineFilter,
  textFilter,
  highlightItems,
  setHighlightItems,
}) => {
  const intl = useIntl();
  useEffect(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const next7 = new Date(today);
    next7.setDate(today.getDate() + 7);

    const next30 = new Date(today);
    next30.setDate(today.getDate() + 30);

    const highlighted = content?.Events?.filter((event) => {
      const eventStart = new Date(event?.start);
      eventStart.setHours(0, 0, 0, 0);

      if (
        dateFilter === 'today' &&
        eventStart.toDateString() !== today.toDateString()
      ) {
        return false;
      }
      if (
        dateFilter === 'next7' &&
        (eventStart > next7 || eventStart < today)
      ) {
        return false;
      }
      if (
        dateFilter === 'next30' &&
        (eventStart > next30 || eventStart < today)
      ) {
        return false;
      }
      if (forChildrenFilter && event?.forChildren !== 'yes') {
        return false;
      }
      if (onlineFilter && event?.isOnline !== 'yes') {
        return false;
      }
      if (
        textFilter &&
        !event?.title?.toLowerCase().includes(textFilter?.toLowerCase()) &&
        !event?.description?.toLowerCase().includes(textFilter?.toLowerCase())
      ) {
        return false;
      }

      if (JSON.parse(event?.highlightEvent) !== true) {
        return false;
      }
      return true;
    });

    setHighlightItems(highlighted || []);
  }, [
    dateFilter,
    forChildrenFilter,
    onlineFilter,
    textFilter,
    setHighlightItems,
    content?.Events,
  ]);

  return highlightItems?.length > 0 ? (
    <Row className="border-top row-column-border row-column-menu-left cardDiv-eventContainer">
      <div>
        <div className="container p-3 p-md-5">
          <h2 className="mb-4 ">
            {intl.formatMessage(messages.highlightEvents)}
          </h2>
          <Row>
            {highlightItems?.map((item, index) => (
              <div className="col-lg-6 col-xl-4 my-2">
                <div className="card-wrapper border border-light rounded shadow-sm">
                  <div className="card no-after rounded">
                    <div className="img-responsive-wrapper">
                      <div className="img-responsive img-responsive-panoramic {{imgClass}}">
                        <ContentImage
                          content={content}
                          position="afterHeader"
                        />
                        <figure className="img-wrapper">
                          {item.image_scales?.banner ? (
                            <img
                              src={
                                item['@id'] +
                                '/' +
                                item.image_scales.banner[0].download
                              }
                              alt={item.description}
                            />
                          ) : (
                            <img
                              src={
                                'https://www.comune.pordenone.it/it/banners/banner1.jpg/@@images/f925e445-944b-47e9-af76-afe61cd91834.jpeg'
                              }
                              alt={'event Image'}
                            />
                          )}
                        </figure>
                        <div className="card-calendar d-flex flex-column justify-content-center">
                          <span className="card-date">
                            {viewDate(intl.locale, item?.start).format('DD')}
                          </span>
                          <span className="card-day">
                            {viewDate(intl.locale, item?.start).format('MMMM')}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      {item?.category && item?.category !== 'null' && (
                        <div className="category-top">
                          <p className="text-primary">
                            <strong>{item?.category}</strong>
                          </p>
                        </div>
                      )}
                      <h3 className="card-title">
                        <a
                          href={flattenToAppURL(item['@id'])}
                          className="text-decoration-none"
                        >
                          {item.title}
                        </a>
                      </h3>
                      <p className="card-text text-tertiary pb-3">
                        {item?.description}
                      </p>
                      <a
                        className="read-more t-primary text-uppercase"
                        href={flattenToAppURL(item['@id'])}
                        aria-label="Leggi di più sulla pagina di {{card.title}}"
                      >
                        <span className="text">
                          {intl.formatMessage(messages.readMore)}
                        </span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-arrow-right-short"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </div>
      </div>
    </Row>
  ) : (
    <></>
  );
};
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PNHighlightEvents.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    Events: PropTypes.object,
  }).isRequired,
};

export default PNHighlightEvents;
