/**
 * ReservationTime view component.
 * @module components/View/ReservationTime
 */

import PropTypes from 'prop-types';
import {
  Card,
  StepperContainer,
  StepperContent,
  CardBody,
} from 'design-react-kit';
import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { expandToBackendURL } from '@plone/volto/helpers';
import {
  getAvaiableSlots,
  getBookings,
} from 'comune-pordenone-plone-theme/actions';

const messages = defineMessages({
  avaiableAppointments: {
    id: 'avaiableAppointments',
    defaultMessage: 'Appuntamenti disponibili',
  },
  office: {
    id: 'office',
    defaultMessage: 'Ufficio',
  },
  officeDescription: {
    id: 'officeDescription',
    defaultMessage: "Scegli l'ufficio a cui vuoi richiedere l'appuntamento",
  },
  officeType: {
    id: 'officeType',
    defaultMessage: 'Tipologia di ufficio',
  },
  go_back: {
    id: 'go_back',
    defaultMessage: 'Indietro',
  },
  save_request: {
    id: 'save_request',
    defaultMessage: 'Salva richiesta',
  },
  advance: {
    id: 'advance',
    defaultMessage: 'Avanti',
  },
  infoPrivacy: {
    id: 'infoPrivacy',
    defaultMessage: 'Informativa privacy',
  },
  january: {
    id: 'january',
    defaultMessage: 'Gennaio',
  },
  february: {
    id: 'february',
    defaultMessage: 'Febbraio',
  },
  march: {
    id: 'march',
    defaultMessage: 'Marzo',
  },
  april: {
    id: 'april',
    defaultMessage: 'Aprile',
  },
  may: {
    id: 'may',
    defaultMessage: 'Maggio',
  },
  june: {
    id: 'june',
    defaultMessage: 'Giugno',
  },
  july: {
    id: 'july',
    defaultMessage: 'Luglio',
  },
  august: {
    id: 'august',
    defaultMessage: 'Agosto',
  },
  september: {
    id: 'september',
    defaultMessage: 'Settembre',
  },
  october: {
    id: 'october',
    defaultMessage: 'Ottobre',
  },
  november: {
    id: 'november',
    defaultMessage: 'Novembre',
  },
  december: {
    id: 'december',
    defaultMessage: 'Dicembre',
  },
  selectMonth: {
    id: 'selectMonth',
    defaultMessage: 'Seleziona un mese',
  },
  noDaysAvail: {
    id: 'noDaysAvail',
    defaultMessage: 'Nessun appuntamento disponibile per questo mese.',
  },
});

/**
 * ReservationTime view component class.
 * @function ReservationTime
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */

const ReservationTime = ({
  currentState,
  setSelectedAppointment,
  filteredAppointments,
  setFilteredAppointments,
  months,
  selectedMonth,
  setSelectedMonth,
  formatAppointmentDate,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const baseURL = expandToBackendURL(`it/amministrazione/reservations-folder`);
  const appointmentsAvailable = useSelector(
    (state) => state.avaiableSlots.result.items,
  );

  useEffect(() => {
    dispatch(getAvaiableSlots(baseURL));
    dispatch(getBookings(baseURL));
  }, []);

  useEffect(() => {
    if (selectedMonth) {
      const filtered = appointmentsAvailable?.filter((appointment) => {
        const appointmentMonth = appointment.split('-')[1];
        return appointmentMonth === selectedMonth;
      });
      setFilteredAppointments(filtered);
    } else {
      setFilteredAppointments([]);
    }
  }, [selectedMonth, appointmentsAvailable]);

  const handleMonthChange = (event) => {
    setSelectedMonth(event?.target?.value);
  };

  const handleAppointmentSelection = (event, id) => {
    setSelectedAppointment(event);
    const element = document.getElementById(id);
    // document.getElementById().focus();
    if (element) {
      element.focus({ preventScroll: true });
    }
  };

  return currentState === 1 ? (
    // <>
    <RichTextSection
      tag_id={intl.formatMessage(messages.avaiableAppointments)}
      field="text"
      title={intl.formatMessage(messages.avaiableAppointments)}
      show_title={false}
      className={'StepperContainer'}
    >
      <StepperContainer>
        <StepperContent
          className="steppers-content border-0 mb-4 h-auto"
          aria-live="polite"
        >
          <div className="col-10">
            <section className="it-page-section">
              <h2>{intl.formatMessage(messages.avaiableAppointments)}</h2>
              <Card className="card p-big reservationCard-style">
                <CardBody className="px-0 mt-4">
                  <div className="select-wrapper">
                    <select
                      placeholder={intl.formatMessage(messages.selectMonth)}
                      id="time-option"
                      className="p-0 col-12"
                      value={selectedMonth}
                      onChange={handleMonthChange}
                    >
                      <option value="">
                        {intl.formatMessage(messages.selectMonth)}
                      </option>
                      {months.map((month) => (
                        <option key={month?.value} value={month?.value}>
                          {month?.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  {selectedMonth && (
                    <div className="cmp-card-radio-list mt-4">
                      <div className="card p-3">
                        <div className="card-body p-0">
                          <div className="form-check m-0 d-flex flex-wrap justify-content-center">
                            {filteredAppointments?.length > 0 ? (
                              filteredAppointments.map((appointment) => (
                                <div
                                  className="radio-body py-3 col-6"
                                  key={`id-${appointment}`}
                                >
                                  <input
                                    type="radio"
                                    id={`appointment-${appointment}`}
                                    name="selectedAppointment"
                                    value={appointment}
                                    onChange={(e) =>
                                      handleAppointmentSelection(
                                        e?.target?.value,
                                        `appointment-${appointment}`,
                                      )
                                    }
                                  />
                                  <label htmlFor={`appointment-${appointment}`}>
                                    <strong>
                                      {formatAppointmentDate(appointment)}
                                    </strong>
                                  </label>
                                </div>
                              ))
                            ) : (
                              <p>{intl.formatMessage(messages.noDaysAvail)}</p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </section>
          </div>
        </StepperContent>
      </StepperContainer>
    </RichTextSection>
  ) : (
    // </>
    ''
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ReservationTime.propTypes = {
  content: PropTypes.shape({
    relatedItems: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  currentState: PropTypes.number.isRequired,
};

export default ReservationTime;
