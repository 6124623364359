/**
 * ReservationDetails view component.
 * @module components/View/ReservationDetails
 */

import PropTypes from 'prop-types';
import {
  Card,
  StepperContainer,
  StepperContent,
  CardHeader,
  CardBody,
  TextArea,
} from 'design-react-kit';
import React, { useState, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { useSelector, useDispatch } from 'react-redux';
import { getContent } from '@plone/volto/actions';

const messages = defineMessages({
  reason: {
    id: 'reason',
    defaultMessage: 'Motivo',
  },
  reasonDescription: {
    id: 'reasonDescription',
    defaultMessage: "Scegli il motivo dell'appuntamento",
  },
  details: {
    id: 'details',
    defaultMessage: 'Dettagli',
  },
  detailsDescription: {
    id: 'detailsDescription',
    defaultMessage: 'Aggiungi ulteriori dettagli',
  },
  maxLenght: {
    id: 'maxLenght',
    defaultMessage: 'Inserire massimo 200 caratteri',
  },
  choseOption: {
    id: 'choseOption',
    defaultMessage: 'Seleziona opzione',
  },
});

/**
 * ReservationDetails view component class.
 * @function ReservationDetails
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const ReservationDetails = ({
  currentState,
  setSelectedType,
  setMoreDetails,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [externalContent, setExternalContent] = useState(null);
  const reservationsFolder = 'it/amministrazione/reservations-folder';
  const folderContext = useSelector(
    (state) => state.content.subrequests?.['external-content']?.data,
  );

  useEffect(() => {
    dispatch(getContent(reservationsFolder, null, 'external-content'));
  }, [dispatch]);

  useEffect(() => {
    if (folderContext) {
      setExternalContent(folderContext);
    }
  }, [folderContext]);

  const handleSelectedType = (event) => {
    event.preventDefault();
    const selectedTypeName = event.target.value;
    const selectedType = folderContext.booking_types.find(
      (type) => type.name === selectedTypeName,
    );
    setSelectedType(selectedType);
  };

  const handleMoreDetails = (event) => {
    setMoreDetails(event.target.value);
  };
  return currentState === 2 ? (
    <>
      <RichTextSection
        tag_id={intl.formatMessage(messages.details)}
        field="text"
        title={intl.formatMessage(messages.details)}
        show_title={false}
      >
        <StepperContainer>
          <StepperContent
            className="steppers-content border-0"
            aria-live="polite"
          >
            <div className="col-10">
              <section className="it-page-section">
                <Card className="card p-big reservationCard-style">
                  <CardHeader className="card-header border-0 p-0 mb-lg-30">
                    <h2>{intl.formatMessage(messages.reason)}</h2>
                    <p className="subtitle-small  mb-0">
                      {intl.formatMessage(messages.reasonDescription)}
                    </p>
                  </CardHeader>
                  <CardBody className="px-0 my-4">
                    <div className="select-wrapper">
                      <select
                        placeholder={intl.formatMessage(messages.choseOption)}
                        id={intl.formatMessage(messages.choseOption)}
                        className="p-0 col-10"
                        onChange={handleSelectedType}
                      >
                        <option
                          id={`option-${intl.formatMessage(
                            messages.choseOption,
                          )}`}
                          className="p-0 col-10"
                        >
                          {intl.formatMessage(messages.choseOption)}
                        </option>
                        {folderContext?.booking_types?.map(
                          (type) =>
                            type?.hidden === false && (
                              <option
                                placeholder={`Seleziona ${type?.name}`}
                                id={`${type?.name}`}
                                className="p-0 col-10"
                              >
                                {type?.name}
                              </option>
                            ),
                        )}
                      </select>
                    </div>
                  </CardBody>
                </Card>
              </section>
            </div>
          </StepperContent>
        </StepperContainer>
        <StepperContainer>
          <StepperContent
            className="steppers-content border-0"
            aria-live="polite"
          >
            <div className="col-10">
              <section className="it-page-section">
                <Card className="card p-big reservationCard-style">
                  <CardHeader className="card-header border-0 p-0 mb-lg-30">
                    <h2>{intl.formatMessage(messages.details)}</h2>
                    <p className="subtitle-small  mb-0">
                      {intl.formatMessage(messages.detailsDescription)}
                    </p>
                  </CardHeader>
                  <CardBody className="px-0 mt-4">
                    <div className="select-wrapper">
                      <TextArea
                        id={intl.formatMessage(messages.details)}
                        infoText={intl.formatMessage(messages.maxLenght)}
                        label={' '}
                        onChange={handleMoreDetails}
                      />
                    </div>
                  </CardBody>
                </Card>
              </section>
            </div>
          </StepperContent>
        </StepperContainer>
      </RichTextSection>
    </>
  ) : (
    ''
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ReservationDetails.propTypes = {
  content: PropTypes.shape({
    relatedItems: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default ReservationDetails;
