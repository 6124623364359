/**
 * Reservation information
 * @module src/actions/getReservationInfo
 */
export const GET_AVAIABLE_SLOTS = 'GET_AVAIABLE_SLOTS';
export const CREATE_RESERVATION = 'CREATE_RESERVATION';
export const GET_RESERVATIONS = 'GET_RESERVATIONS';
export const DELETE_RESERVATION = 'DELETE_RESERVATION';

/**
 * Get avaiable slots.
 * @function getAvaiableSlots
 * @returns {Object} Get avaiable slots action.
 */
export function getAvaiableSlots(path) {
  return {
    type: GET_AVAIABLE_SLOTS,
    request: {
      op: 'get',
      path: `${path}/@available-slots`,
      headers: {
        Accept: 'application/json',
      },
    },
  };
}

/**
 * create reservation.
 * @function createBooking
 * @returns {Object} Create reservation action.
 */
export function createBooking(path, data) {
  return {
    type: CREATE_RESERVATION,
    request: {
      op: 'post',
      path: `${path}/@booking`,
      data: {
        booking_date: data?.booking_date,
        booking_type: data?.booking_type,
        fields: [
          {
            name: 'title',
            value: data?.fields?.find((field) => field.name === 'title')?.value,
          },
          {
            name: 'fullname',
            value: data?.fields?.find((field) => field.name === 'fullname')
              ?.value,
          },
          {
            name: 'email',
            value: data?.fields?.find((field) => field.name === 'email')?.value,
          },
        ],
      },
      headers: {
        Authorization: 'Basic ' + Buffer.from('admin:admin').toString('base64'),
      },
    },
  };
}

/**
 * get reservations.
 * @function getBookings
 * @returns {Object} get reservations action.
 */
export function getBookings(path) {
  return {
    type: GET_RESERVATIONS,
    request: {
      op: 'get',
      path: `${path}/@bookings`,
      headers: {
        Authorization: 'Basic ' + Buffer.from('admin:admin').toString('base64'),
      },
    },
  };
}

/**
 * delete reservation.
 * @function deleteBooking
 * @returns {Object} delete reservation action.
 */
export function deleteBooking(path, uid) {
  return {
    type: DELETE_RESERVATION,
    request: {
      op: 'delete',
      path: `${path}/@booking/${uid}`,
      headers: {
        Authorization: 'Basic ' + Buffer.from('admin:admin').toString('base64'),
      },
    },
  };
}
