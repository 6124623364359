/**
 * File attribute.
 * @module fields/file
 *
 */

import { defineMessages, useIntl } from 'react-intl';
import { setAutoValue, updateVisibility, validateValue } from './../utilities/fieldshelper';
import { useEffect, useState } from 'react';

import { Input } from 'design-react-kit';
import { getFieldLabel } from './utils';
import { uploadFileRequest } from '../proxies/utils';
import { useProcessModel } from '../modelsuses';
import { fetchAllowedExtensions, getDefaultCategory } from '../proxies';

interface FileFieldProps {
  config: {
    name: string;
    mandatory?: boolean;
    help?: string;
  };
  context: Record<string, any>;
  recordUpdater: (context: Record<string, any>) => void;
  showIfFn: Function;
  showIfBindings: any;
  showIfApis: any;
  validationRuleFn: Function;
  validationRuleBindings: any;
  validationRuleApis: any;
  autoValueFn: Function;
  autoValueBindings: any;
  autoValueApis: any;
}

const messages = defineMessages({
  fileLoading: {
    id: 'fileLoading',
    defaultMessage: 'Stai caricando il file: ',
  },
  uploadFile: {
    id: 'uploadFile',
    defaultMessage: 'Carica il file',
  },
});

export const FileField: React.FC<FileFieldProps> = ({ config, context, recordUpdater, showIfFn, showIfBindings, showIfApis, validationRuleFn, validationRuleBindings, validationRuleApis, autoValueFn, autoValueBindings, autoValueApis }) => {
  const intl = useIntl();
  const fieldId = `field-string-${config.name}`;
  const required = config.mandatory ? true : undefined;

  const [value, setValue] = useState<string | undefined>(context?.[config.name]);
  const [errors, setErrors] = useState<string>('');
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [infoText, setInfoText] = useState<string>('');
  const [allowedExtensions, setAllowedExtensions] = useState<string>();
  const [formattedExtensions, setFormattedExtensions] = useState<string>();
  const [fileData, setFileData] = useState({} as { name: string });
  const { getProcess } = useProcessModel();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [dmsCategory, setDmsCategory] = useState<string>('');

  const updateFileData = ({ _id, name }: { _id: string; name: string }) => {
    setValue(_id);
    setFileData({
      name: name,
    });
    context[config.name] = _id;
    recordUpdater({
      ...context,
    });
  };

  /**
   * On value change
   */
  const onValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const uploaded_file = event.target.files?.[0];
    if (uploaded_file) {
      setIsUploading(true);

      uploadFileRequest('TEMP', uploaded_file, {}).then((data: { _id: string; name: string }) => {
        updateFileData(data);
        event.target.value = '';
        setIsUploading(false);
      });
    }
  };

  /**
   * string to HEX function
   */
  const stringToHex = (s: string): string => {
    if (s && !s.startsWith('HEX')) {
      const utf8 = unescape(encodeURIComponent(s));
      const hex = Array.from(utf8)
        .map((char) => char.charCodeAt(0).toString(16).padStart(2, '0'))
        .join('');
      return 'HEX' + hex;
    }
    return s;
  };
  /**
   * Get process
   */
  useEffect(
    () => {
      const fetchCategory = async () => {
        const process = await getProcess(context?._type);
        if (process?.dmsCategory) {
          setDmsCategory(process?.dmsCategory);
        } else {
          const category = await getDefaultCategory();
          setDmsCategory(category);
        }
      };

      fetchCategory();
    },
    context?._type,
  );

  useEffect(() => {
    const getExtensions = async () => {
      if (dmsCategory) {
        const categories = await fetchAllowedExtensions(stringToHex);
        const category = categories?.data?.find((cat: { code: string }) => cat.code === config?.dmsCategory);
        if (category?.code === config?.dmsCategory) {
          setAllowedExtensions(category.allowedExtensions);
        }
      }
    };

    getExtensions();
  }, [dmsCategory]);

  /**
   * Format allowed extensions
   */
  useEffect(() => {
    if (allowedExtensions) {
      setFormattedExtensions(
        allowedExtensions
          .split(',')
          .map((ext) => `.${ext.trim()}`)
          .join(', '),
      );
    } else {
      setFormattedExtensions('');
    }
  }, [allowedExtensions]);

  /**
   * On context change
   */
  useEffect(
    () => {
      if (context?.[config.name]) {
        updateFileData({ _id: context?.[config.name], name: context?.[`_${config.name}_name`] });
      }
    },
    context?.[config.name],
  );

  /**
   * Update visibility rules
   */
  useEffect(() => {
    updateVisibility(showIfFn, showIfApis, setIsVisible, setIsDisabled);
  }, showIfBindings);

  useEffect(() => {
    if (fileData?.name) {
      setInfoText(intl.formatMessage(messages.fileLoading) + fileData.name);
    }
  }, [fileData?.name]);

  /**
   * Validation rules
   */
  useEffect(() => {
    validateValue(value, config?.mandatory, setErrors, validationRuleFn, validationRuleApis, intl);
  }, validationRuleBindings);

  /**
   * AutoValue rules
   */
  useEffect(() => {
    setAutoValue(autoValueFn, autoValueApis);
  }, autoValueBindings);

  return (
    <div className="form-group" hidden={!isVisible}>
      <label htmlFor={fieldId} className="active">
        {getFieldLabel(config)}
      </label>
      <Input
        type="file"
        noWrapper={true}
        id={fieldId}
        validationText={errors}
        disabled={isDisabled || isUploading}
        valid={errors ? false : value ? true : undefined}
        required={required}
        infoText={infoText}
        onChange={onValueChange}
        placeholder={intl.formatMessage(messages.fileLoading)} 
        accept={formattedExtensions} />
      {infoText && (
        <div>
          <small id={`${fieldId}FileName`} className="form-text text-muted">
            <em>{infoText}</em>
          </small>
        </div>
      )}
      <div hidden={!config?.help}>
        <small id={`${fieldId}Description`} className="form-text">
          {config?.help}
        </small>
      </div>
      <div className="invalid-feedback form-feedback just-validate-error-label form-text form-feedback just-validate-error-label" hidden={!errors}>
        {errors}
      </div>
    </div>
  );
};
