/**
 * PNReservationFolder view component.
 * @module components/View/PNReservationFolder
 */
import { defineMessages, useIntl } from 'react-intl';
import {
  PageHeader,
  RelatedItems,
  useSideMenu,
  ContentTypeViewSections,
  SkipToMainContent,
  richTextHasContent,
  RichTextSection,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { PNAlertBehavior } from 'comune-pordenone-plone-theme/components/View';
import { createRef, useState } from 'react';

import Image from '@plone/volto/components/theme/Image/Image';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'design-react-kit';
import PNReservationFolderItemsType from './PNReservationFolderItemsType';

export const ReservationSectionsOrder = [
  { /* RESERVATIONS */ component: PNReservationFolderItemsType },
];

const messages = defineMessages({
  descriptionAgenda: {
    id: 'descriptionAgenda',
    defaultMessage: 'Descrizione agenda',
  },
});
/**
 * PNReservationFolder view component class.
 * @function PNReservationFolder
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const PNReservationFolder = ({ content, location }) => {
  const intl = useIntl();
  const [readingtime, setReadingtime] = useState(0);
  let documentBody = createRef();
  const { sideMenuElements, SideMenu } = useSideMenu(content, documentBody);

  return (
    <>
      <Container className="container px-4 my-4 newsitem-view">
        <SkipToMainContent />
        <PageHeader
          content={content}
          readingtime={readingtime}
          showreadingtime={true}
          showdates={true}
          showtassonomiaargomenti={true}
        />

        {/* <PNAlertBehavior
          text={content?.alertText}
          expirationDate={content?.alertExpiration}
        /> */}

        {/* HEADER IMAGE */}
        <Row className="border-top row-column-border row-column-menu-left">
          <Col tag="aside" lg={4}>
            {documentBody.current && __CLIENT__ && (
              <SideMenu data={sideMenuElements} content_uid={content?.UID} />
            )}
          </Col>
          <section
            ref={documentBody}
            id="reservation-main-content-section"
            className="col-lg-8 it-page-sections-container"
          >
            {richTextHasContent(content?.descriptionAgenda) && (
              <RichTextSection
                tag_id={'agenda-description'}
                field="text"
                title={intl.formatMessage(messages.descriptionAgenda)}
                show_title={true}
                data={content?.descriptionAgenda}
                // className={'mb-5'}
              />
            )}
            <ContentTypeViewSections
              content={content}
              defaultSections={ReservationSectionsOrder}
            />
          </section>
        </Row>
      </Container>
      <RelatedItems list={content?.relatedItems} />
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PNReservationFolder.propTypes = {
  content: PropTypes.shape({
    relatedItems: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default PNReservationFolder;
