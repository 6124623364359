/**
 * ReservationSummary view component.
 * @module components/View/ReservationSummary
 */

import PropTypes from 'prop-types';
import {
  StepperContainer,
  Card,
  StepperContent,
  CardBody,
  CardHeader,
  Input,
  TextArea,
  Button,
} from 'design-react-kit';
import { useState, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { useSelector, useDispatch } from 'react-redux';
import { getContent } from '@plone/volto/actions';
import cx from 'classnames';

const messages = defineMessages({
  summary: {
    id: 'summary',
    defaultMessage: 'Riepilogo',
  },
  office: {
    id: 'office',
    defaultMessage: 'Ufficio',
  },
  officeType: {
    id: 'officeType',
    defaultMessage: 'Tipologia ufficio',
  },
  officeDescription: {
    id: 'officeDescription',
    defaultMessage: 'Descrizione ufficio',
  },
  time: {
    id: 'time',
    defaultMessage: 'Data e orario',
  },
  detailsAppointment: {
    id: 'detailsAppointment',
    defaultMessage: 'Dettagli appuntamento',
  },
  details: {
    id: 'details',
    defaultMessage: 'Dettagli appuntamento',
  },
  requester: {
    id: 'requester',
    defaultMessage: 'Richiedente Attivo',
  },
  date: {
    id: 'date',
    defaultMessage: 'Data',
  },
  hour: {
    id: 'hour',
    defaultMessage: 'Ora',
  },
  reason: {
    id: 'reason',
    defaultMessage: 'Motivo',
  },
  choseOption: {
    id: 'choseOption',
    defaultMessage: 'Seleziona opzione',
  },
  edit: {
    id: 'edit',
    defaultMessage: 'Modifica',
  },
  saveEdits: {
    id: 'saveEdits',
    defaultMessage: 'Salva modifiche',
  },
  selectMonth: {
    id: 'selectMonth',
    defaultMessage: 'Seleziona un mese',
  },
  noDaysAvail: {
    id: 'noDaysAvail',
    defaultMessage: 'Nessun appuntamento disponibile per questo mese.',
  },
  invalidDates: {
    id: 'invalidDates',
    defaultMessage: 'La data selezionata non è valida',
  },
});
/**
 * ReservationSummary view component className.
 * @function ReservationSummary
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const ReservationSummary = ({
  currentState,
  selectedAppointment,
  setSelectedAppointment,
  selectedType,
  setSelectedType,
  moreDetails,
  setMoreDetails,
  requesterData,
  setRequesterData,
  filteredAppointments,
  months,
  selectedMonth,
  setSelectedMonth,
  formatAppointmentDate,
  setMessageError,
}) => {
  const intl = useIntl();
  const [timeReadOnly, setTimeReadOnly] = useState(true);
  const [detailsReadOnly, setDetailsReadOnly] = useState(true);
  const [requesterReadOnly, setRequesterReadOnly] = useState(true);
  const appointmentDate = new Date(selectedAppointment);
  const [timeButtonText, setTimeButtonText] = useState(
    intl.formatMessage(messages.edit),
  );
  const [detailsButtonText, setDetailsButtonText] = useState(
    intl.formatMessage(messages.edit),
  );
  const [requesterButtonText, setRequesterButtonText] = useState(
    intl.formatMessage(messages.edit),
  );

  const dayAppointment = appointmentDate.toLocaleDateString('it-IT', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });
  const timeAppointment = appointmentDate.toLocaleTimeString('it-IT', {
    hour: '2-digit',
    minute: '2-digit',
  });

  useEffect(() => {
    !appointmentDate && currentState === 4
      ? setMessageError(intl.formatMessage(messages.invalidDates))
      : setMessageError('');
  }, [selectedAppointment]);

  const handleTimeEdit = () => {
    setTimeReadOnly(!timeReadOnly);
    setTimeButtonText(
      timeReadOnly
        ? intl.formatMessage(messages.saveEdits)
        : intl.formatMessage(messages.edit),
    );
  };

  const handleSelectedType = (typeName) => {
    setSelectedType(() => ({
      name: typeName,
    }));
  };

  const handleDetailsEdit = () => {
    setDetailsReadOnly(!detailsReadOnly);
    setDetailsButtonText(
      detailsReadOnly
        ? intl.formatMessage(messages.saveEdits)
        : intl.formatMessage(messages.edit),
    );
  };
  const handleRequesterDataChange = (key, value) => {
    setRequesterData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleRequesterEdit = () => {
    setRequesterReadOnly(!requesterReadOnly);
    setRequesterButtonText(
      requesterReadOnly
        ? intl.formatMessage(messages.saveEdits)
        : intl.formatMessage(messages.edit),
    );
  };

  const dispatch = useDispatch();
  const [externalContent, setExternalContent] = useState(null);
  const reservationsFolder = 'it/amministrazione/reservations-folder';
  const folderContext = useSelector(
    (state) => state.content.subrequests?.['external-content']?.data,
  );

  useEffect(() => {
    dispatch(getContent(reservationsFolder, null, 'external-content'));
  }, [dispatch]);

  useEffect(() => {
    if (folderContext) {
      setExternalContent(folderContext);
    }
  }, [folderContext]);

  const handleMonthChange = (event) => {
    event.preventDefault();
    setSelectedMonth(event.target.value);
  };

  const handleAppointmentSelection = (event) => {
    event.preventDefault();
    setSelectedAppointment(event);
  };

  return (
    currentState === 4 && (
      <>
        <RichTextSection
          tag_id={intl.formatMessage(messages.summary)}
          field="text"
          title={intl.formatMessage(messages.summary)}
          show_title={false}
        >
          <StepperContainer>
            <StepperContent
              className="steppers-content border-0 h-100"
              aria-live="polite"
            >
              <div className="col-10 h-100">
                <section className="it-page-section h-100">
                  <h2 className="mt-4">
                    {intl.formatMessage(messages.summary)}
                  </h2>
                  {/* TIME */}
                  {dayAppointment && timeAppointment ? (
                    <Card className="card p-big my-4 h-100">
                      <CardHeader className="border-0 mb-lg-30 px-5 d-flex justify-content-between">
                        <span>
                          <h4>{intl.formatMessage(messages.time)}</h4>
                        </span>
                        <span>
                          <Button
                            className="text-primary"
                            onClick={handleTimeEdit}
                          >
                            {timeButtonText}
                          </Button>
                        </span>
                      </CardHeader>
                      <CardBody
                        className={cx('px-5', {
                          'd-none': !timeReadOnly,
                        })}
                      >
                        <div className="single-line-info border-bottom border-light">
                          <div className="text-paragraph-small">
                            {intl.formatMessage(messages.date)}
                          </div>
                          <div className="border-light">
                            <strong>
                              <p className="data-text">{dayAppointment}</p>
                            </strong>
                          </div>
                        </div>
                        <div className="single-line-info border-bottom border-light">
                          <div className="text-paragraph-small">
                            {intl.formatMessage(messages.hour)}
                          </div>
                          <div className="border-light">
                            <strong>
                              <p className="data-text">{timeAppointment}</p>
                            </strong>
                          </div>
                        </div>
                      </CardBody>
                      <CardBody
                        className={cx('px-5', {
                          'd-none': timeReadOnly,
                        })}
                      >
                        <div className={'select-wrapper'}>
                          <select
                            placeholder={intl.formatMessage(
                              messages.selectMonth,
                            )}
                            id="time-option"
                            className="p-0 col-12"
                            value={selectedMonth}
                            onChange={handleMonthChange}
                          >
                            <option value="">
                              {intl.formatMessage(messages.selectMonth)}
                            </option>
                            {months.map((month) => (
                              <option key={month.value} value={month.value}>
                                {month.label}
                              </option>
                            ))}
                          </select>
                          {selectedMonth && (
                            <div className="cmp-card-radio-list mt-4">
                              <div className="card p-3">
                                <div className="card-body p-0">
                                  <div className="form-check m-0 d-flex flex-wrap">
                                    {filteredAppointments?.length > 0 ? (
                                      filteredAppointments.map(
                                        (appointment) => (
                                          <div
                                            className="radio-body border-bottom border-light py-3 col-6"
                                            key={`id-${appointment}`}
                                          >
                                            <input
                                              type="radio"
                                              id={`appointment-${appointment}`}
                                              name="selectedAppointment"
                                              value={appointment}
                                              onChange={(e) =>
                                                handleAppointmentSelection(
                                                  e.target.value,
                                                )
                                              }
                                            />
                                            <label
                                              htmlFor={`appointment-${appointment}`}
                                            >
                                              <strong>
                                                {formatAppointmentDate(
                                                  appointment,
                                                )}
                                              </strong>
                                            </label>
                                          </div>
                                        ),
                                      )
                                    ) : (
                                      <p>
                                        {intl.formatMessage(
                                          messages.noDaysAvail,
                                        )}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  ) : (
                    <></>
                  )}
                  {/* DETAILS */}
                  {selectedType || moreDetails ? (
                    <Card className="card p-big my-4 h-100">
                      <CardHeader className="border-0 mb-lg-30 px-5 d-flex justify-content-between">
                        <span>
                          <h4>
                            {intl.formatMessage(messages.detailsAppointment)}
                          </h4>
                        </span>
                        <span>
                          <Button
                            className="text-primary"
                            onClick={handleDetailsEdit}
                          >
                            {detailsButtonText}
                          </Button>
                        </span>
                      </CardHeader>
                      <CardBody className="px-5">
                        <div className="single-line-info border-bottom border-light pb-4">
                          <div className="text-paragraph-small">
                            {intl.formatMessage(messages.reason)}
                          </div>
                          <select
                            value={selectedType.name}
                            className="col-12"
                            disabled={detailsReadOnly}
                            onChange={(e) =>
                              handleSelectedType(e.target?.value)
                            }
                          >
                            <option
                              id={`option-${intl.formatMessage(
                                messages.choseOption,
                              )}`}
                            >
                              {intl.formatMessage(messages.choseOption)}
                            </option>
                            {folderContext?.booking_types?.map(
                              (type) =>
                                type?.hidden === false && (
                                  <option
                                    placeholder={`Seleziona ${type?.name}`}
                                    id={`${type?.name}`}
                                    className="p-0 col-10"
                                    key={type?.name}
                                    value={type?.name}
                                  >
                                    {type?.name}
                                  </option>
                                ),
                            )}
                          </select>
                        </div>
                        <div className="single-line-info border-bottom border-light">
                          <div className="text-paragraph-small">
                            {intl.formatMessage(messages.details)}
                          </div>
                          <TextArea
                            value={moreDetails}
                            readOnly={detailsReadOnly}
                            onChange={(e) => setMoreDetails(e.target?.value)}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  ) : (
                    <></>
                  )}
                  {/* REQUESTER */}
                  {requesterData ? (
                    <Card className="card p-big my-4 h-100">
                      <CardHeader className="border-0 mb-lg-30 px-5 d-flex justify-content-between">
                        <span>
                          <h4>{intl.formatMessage(messages.requester)}</h4>
                        </span>
                        <span>
                          <Button
                            className="text-primary"
                            onClick={handleRequesterEdit}
                          >
                            {requesterButtonText}
                          </Button>
                        </span>
                      </CardHeader>
                      <CardBody className="px-5">
                        {Object.entries(requesterData).map(([key, value]) => (
                          <div
                            key={key}
                            className="single-line-info border-bottom border-light"
                          >
                            <div className="text-paragraph-small">{key}</div>
                            <div className="border-light">
                              <Input
                                type="text"
                                className="data-text"
                                readOnly={requesterReadOnly}
                                value={value}
                                onChange={(e) =>
                                  handleRequesterDataChange(
                                    key,
                                    e.target?.value,
                                  )
                                }
                              />
                            </div>
                          </div>
                        ))}
                      </CardBody>
                    </Card>
                  ) : (
                    <></>
                  )}
                </section>
              </div>
            </StepperContent>
          </StepperContainer>
        </RichTextSection>
      </>
    )
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ReservationSummary.propTypes = {
  content: PropTypes.shape({
    relatedItems: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default ReservationSummary;
