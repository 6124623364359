/**
 * Issue view component.
 * @module components/View/Issue
 */
import {
  PageHeader,
  useSideMenu,
  ContentTypeViewSections,
  SkipToMainContent,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { defineMessages, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { createRef, useState } from 'react';
import { useSelector } from 'react-redux';
import IssueStepper from './IssueStepper';
import IssuePrivacy from './IssuePrivacy';
import IssueData from './IssueData';
import IssueSummary from './IssueSummary';
import { Container } from 'design-react-kit';
import IssueStepperNav from './IssueStepperNav';

export const IssueSectionsOrder = [
  { /* DATA */ component: IssueData },
  { /* SUMMARY */ component: IssueSummary },
  { /* STEPPERNAV */ component: IssueStepperNav },
];

/**
 * Issue view component class.
 * @function Issue
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const Issue = () => {
  let documentBody = createRef();
  const content = useSelector((state) => state.content.data);
  const { sideMenuElements, SideMenu } = useSideMenu(content, documentBody);
  const [readingtime, setReadingtime] = useState(0);
  const [currentState, setCurrentState] = useState(1);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [images, setImages] = useState([]);
  const [address, setAddress] = useState('');
  const [issueType, setIssueType] = useState('');
  const [title, setTitle] = useState('');
  const [details, setDetails] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [fiscalNumber, setFiscalNumber] = useState('');
  const [validationError, setValidationError] = useState(null);
  if (!currentState) {
    setCurrentState(1);
  }
  return (
    <>
      <Container className="container px-4 my-4 newsitem-view">
        <SkipToMainContent />
        <PageHeader
          content={content}
          readingtime={readingtime}
          showreadingtime={true}
          showdates={true}
          showtassonomiaargomenti={true}
        />
        <IssueStepper
          content={content}
          currentState={currentState}
          setCurrentState={setCurrentState}
        />
        {!privacyAccepted ? (
          <IssuePrivacy
            checked={privacyAccepted}
            setChecked={setPrivacyAccepted}
            currentState={currentState}
            setCurrentState={setCurrentState}
          />
        ) : (
          <div className="row row-column-border border-light row-column-menu-left">
            <aside className="col-lg-4"></aside>
            <section
              className="col-lg-8 it-page-sections-container border-light"
              id="main-content-section"
              ref={documentBody}
            >
              {/* SEZIONI */}
              <ContentTypeViewSections
                content={content}
                defaultSections={IssueSectionsOrder.map((section, index) => ({
                  ...section,
                  props: {
                    currentState,
                    setCurrentState,
                    images,
                    setImages,
                    address,
                    setAddress,
                    issueType,
                    setIssueType,
                    title,
                    setTitle,
                    details,
                    setDetails,
                    firstName,
                    setFirstName,
                    lastName,
                    setLastName,
                    phone,
                    setPhone,
                    email,
                    setEmail,
                    fiscalNumber,
                    setFiscalNumber,
                    validationError,
                    setValidationError,
                  },
                }))}
              />
            </section>
          </div>
        )}
      </Container>
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Issue.propTypes = {
  content: PropTypes.shape({}).isRequired,
};

export default Issue;
