/**
 * email issue reducer
 * @module src/reducers/emailissue
 */

import { SEND_EMAIL_ISSUE } from 'comune-pordenone-plone-theme/actions';

const initialState = {
  error: null,
  hasError: false,
  result: {},
  loadingResults: false,
};

export const emailIssueReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${SEND_EMAIL_ISSUE}_PENDING`:
      return {
        ...state,
        loading: true,
      };

    case `${SEND_EMAIL_ISSUE}_SUCCESS`:
      return {
        ...state,
        result: action.result,
        loading: false,
      };

    case `${SEND_EMAIL_ISSUE}_FAIL`:
      return {
        ...state,
        error: action.error,
        hasError: true,
        loading: false,
      };

    default:
      return state;
  }
};
