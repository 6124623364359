/**
 * PNReservationFolderItemsType view component.
 * @module components/View/PNReservationFolderItemsType
 */
import { defineMessages, useIntl } from 'react-intl';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import PropTypes from 'prop-types';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Col, Row } from 'design-react-kit';

export const ReservationSectionsOrder = [];

const messages = defineMessages({
  reservationTypes: {
    id: 'reservationTypes',
    defaultMessage: 'Tipologie prenotazioni',
  },
  readMore: {
    id: 'readMore',
    defaultMessage: 'Leggi di più',
  },
});
/**
 * PNReservationFolderItemsType view component class.
 * @function PNReservationFolderItemsType
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const PNReservationFolderItemsType = ({ content, location }) => {
  const intl = useIntl();

  return content?.items ? (
    <>
      <RichTextSection
        data={content.text}
        tag_id={'text-reservationTypes'}
        field="text"
        title={intl.formatMessage(messages.reservationTypes)}
        show_title={true}
      >
        <Row>
          {content?.items.map((reservation, index) => (
            <Col className="col-lg-6 col-xl-6 my-2">
              <div className="card-wrapper border border-light rounded shadow-sm">
                <div className="card no-after rounded">
                  <div className="card-body">
                    <h3 className="card-title">
                      <a
                        href={flattenToAppURL(reservation['@id'])}
                        className="text-decoration-none"
                      >
                        {reservation.title}
                      </a>
                    </h3>
                    <p className="card-text text-tertiary pb-3">
                      {reservation?.description}
                    </p>
                    <a
                      className="read-more t-primary text-uppercase"
                      href={flattenToAppURL(reservation['@id'])}
                      aria-label="Leggi di più sulla pagina di {{card.title}}"
                    >
                      <span className="text">
                        {intl.formatMessage(messages.readMore)}
                      </span>
                      <span className="visually-hidden">
                        su Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit…
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-arrow-right-short"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </RichTextSection>
    </>
  ) : (
    ''
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PNReservationFolderItemsType.propTypes = {
  content: PropTypes.shape({
    relatedItems: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
};

export default PNReservationFolderItemsType;
